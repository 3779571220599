<div id="lottie-container" style="display: none;"></div>

<div class="container-fluid" style="padding: 0.5%;">
    <div class="row">
        <div class="col-md-4">
            <br><br><br><br>
            <h4 style="text-align: center;">Incidencia por Departamento</h4>
            <div id="grafico" style="height: 500px;height: 500px"></div>
        </div>
        <div class="col-md-8">
            <div id="map" class="p-relative" style="height: 96vh;">
                <div class="contenedor-padre">
                    <div class="divContainer1">
                        <h4>Visor Censista Seguro</h4>
                    </div>
            </div>
                <div class="divContainer">
                    <!--<ng-lottie [options]="options"></ng-lottie>-->
                    <ng-select [items]="departamentos" bindLabel="depto" bindValue="cod_depto" placeholder="Seleccione el Departamento" [(ngModel)]="departamento" (change)="actualizar()">
                    </ng-select><hr/>
                    
                    <label class="element" style="padding: 3%; font-size: 14px; background-color: rgba(0, 136, 255, 0.568);" (click)="logout()">Cerrar Sesión &nbsp;&nbsp;
                        <i class="pi pi-power-off" style="font-size: 14px"></i>
                    </label>
                    
                </div>
            </div>
        </div>
    </div>
</div>


