import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as centroid from "@turf/centroid";
import * as L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { AnimationOptions } from 'ngx-lottie';
import { ServiceService } from '../../service.service';


@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})

export class VisorComponent implements OnInit {
  options: AnimationOptions = {
    path: '../../../assets/lego.json',
  };
  map: L.Map;
  datos1: any;
  wfsStyle = {
    fillOpacity: 0.5,
    fillColor: 'white',
    color: "white",
    weight: 2,
  };
  markerCluster1: L.LayerGroup = L.layerGroup();
  myIcon1 = L.icon({
    iconUrl: '../../../assets/icono.png',
    iconSize: [45, 45],
  });
  departamentos: any;
  departamento: any;
  valor: number;
  datosDepartamento: any;
  sw: number;
  lottieIcon = L.icon({
    iconUrl: '../../../assets/lego.gif',
    iconSize: [55, 55]
  });
  refreshInterval: any;
  constructor(private homologacionService: ServiceService,private router: Router) { }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    clearInterval(this.refreshInterval);
  }

  async ngOnInit() {
    this.refreshInterval = setInterval(() => {
      this.refreshPage();
    }, 300000);
    this.valor=0;
    const data = await this.homologacionService.listaDepartamento();
    this.departamentos= data;
    this.satelite();
    this.selecionar();
  }

  refreshPage() {
    location.reload();
  }

  satelite() {
    const punto = '-16.4990659,-68.1214385'
    this.grafico();
    this.graficoHora();
    this.map = L.map('map').setView([-16.28174,-65.09399], 6.45);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    const tileLayers = {
      satellite: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
      }),

    };
    const controlCapas = L.control.layers(null, null, { position: 'topleft' }).addTo(this.map);
    controlCapas.addOverlay(tileLayers.satellite, 'Satellite');
    this.map.zoomControl.remove();
    this.puntos1();
  }

  async selecionar(){
    const data = await this.homologacionService.departamento()
      this.datosDepartamento = data[0].row_to_json.features;
      const Layer_Manzanas = L.geoJSON(this.datosDepartamento, {
        style: this.estilo_manzanas,
      });
      this.map.addLayer(Layer_Manzanas);
  }

  async puntos1(){
    this.valor=1;
    this.markerCluster1 = L.layerGroup();
    const data = await this.homologacionService.listar(this.departamento)
      this.datos1 = data[0].row_to_json.features;
      const Layer_Edificios = L.geoJSON(this.datos1, {
        onEachFeature: this.onEachFeature1.bind(this),
        pointToLayer: (feature, latlng) => {
          
          return L.marker(latlng, {icon: this.myIcon1});
        }
      })
      this.markerCluster1.addLayer(Layer_Edificios);
      this.markerCluster1.addTo(this.map);
  }

  private onEachFeature1(feature, layer): void {
    
    let popupContent;
    if (feature.properties.tipo=='POLICIA'){
      popupContent = `<h6><b>ALERTA - INE</b></h6><hr/>
      <h6 style="text-align: justify;">La persona <b>${feature.properties.nombre}</b> con edad de <b>${feature.properties.edad}</b> años y numero de celular <b>${feature.properties.celular}</b> envio una alerta en:</h6>
      <h6><b>FECHA:</b> ${feature.properties.fecha}</h6>
      <h6><b>HORA:</b> ${feature.properties.hora}</h6><hr/>
      <h6 style="text-align: justify;">Se envio la alerta al funcionario policial <b>${feature.properties.responsable}</b> de la EPI <b>${feature.properties.epi}</b>.</h6>`;
    } else {
      if (feature.properties.tipo=='JEFE'){
        popupContent = `<h6><b>ALERTA - INE</b></h6><hr/>
        <h6 style="text-align: justify;">La persona <b>${feature.properties.nombre}</b> con edad de <b>${feature.properties.edad}</b> años y numero de celular <b>${feature.properties.celular}</b> envio una alerta en:</h6>
        <h6><b>FECHA:</b> ${feature.properties.fecha}</h6>
        <h6><b>HORA:</b> ${feature.properties.hora}</h6><hr/>
        <h6 style="text-align: justify;">Se envio la alerta al JEFE de zona/area <b>${feature.properties.responsable}</b>.</h6>`;
      } else {
        const resposable = feature.properties.responsable.split(',');
        const epi = feature.properties.epi.split(',');
        popupContent = `<h6><b>ALERTA - INE</b></h6><hr/>
        <h6 style="text-align: justify;">La persona <b>${feature.properties.nombre}</b> con edad de <b>${feature.properties.edad}</b> años y numero de celular <b>${feature.properties.celular}</b> envio una alerta en:</h6>
        <h6><b>FECHA:</b> ${feature.properties.fecha}</h6>
        <h6><b>HORA:</b> ${feature.properties.hora}</h6><hr/>
        <h6 style="text-align: justify;">Se envio la alerta al funcionario policial <b>${resposable[1]}</b> de la EPI <b>${epi[1]}</b>.<br>
        Y se envio la copia de la alerta al JEFE de zona/area <b>${resposable[0]}</b></h6>`;
      }
    }
    
    
      
    
    layer.bindPopup(popupContent);
  }

  estilo_manzanas(feature) {
      return {
        weight: 2.5,
        color: '#071A0D',
        fillcolor: '#071A0D',
        opacity: 1.0,
        fillOpacity: 0.05
      }
  }
  
  actualizar(){
    const ubicacion= this.datosDepartamento.find(res=>res.properties.cod_depto==this.departamento);
    const limite = centroid.default(ubicacion).geometry.coordinates;
    this.map.flyTo([limite[1],limite[0]],8);
  }

  logout() {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  async grafico(){
    const data = await this.homologacionService.grafico()
    let chart4 = am4core.create("grafico", am4charts.XYChart3D);
        chart4.data =data
        let categoryAxis = chart4.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "nombre";
        categoryAxis.renderer.labels.template.rotation = 270;
        categoryAxis.renderer.labels.template.hideOversized = false;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.horizontalCenter = "right";
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        let valueAxis = chart4.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "Numero de Incidencias";
        valueAxis.title.fontWeight = "bold";
        let series = chart4.series.push(new am4charts.ColumnSeries3D());
        series.dataFields.valueY = "total";
        series.dataFields.categoryX = "nombre";
        series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;
        columnTemplate.stroke = am4core.color("#FFFFFF");
        columnTemplate.adapter.add("fill", function(fill, target: any) {
          return chart4.colors.getIndex(target.dataItem.index);
        })
        columnTemplate.adapter.add("stroke", function(stroke, target: any) {
          return chart4.colors.getIndex(target.dataItem.index);
        })
        chart4.cursor = new am4charts.XYCursor();
        chart4.cursor.lineX.strokeOpacity = 0;
        chart4.cursor.lineY.strokeOpacity = 0;
  }

  async graficoHora(){
    let chart = am4core.create("chartdiv", am4charts.XYChart);

chart.data = [{
  "year": "1994",
  "cars": 1587,
  "motorcycles": 650,
  "bicycles": 121
}, {
  "year": "1995",
  "cars": 1567,
  "motorcycles": 683,
  "bicycles": 146
}, {
  "year": "1996",
  "cars": 1617,
  "motorcycles": 691,
  "bicycles": 138
}, {
  "year": "1997",
  "cars": 1630,
  "motorcycles": 642,
  "bicycles": 127
}, {
  "year": "1998",
  "cars": 1660,
  "motorcycles": 699,
  "bicycles": 105
}, {
  "year": "1999",
  "cars": 1683,
  "motorcycles": 721,
  "bicycles": 109
}, {
  "year": "2000",
  "cars": 1691,
  "motorcycles": 737,
  "bicycles": 112
}, {
  "year": "2001",
  "cars": 1298,
  "motorcycles": 680,
  "bicycles": 101
}, {
  "year": "2002",
  "cars": 1275,
  "motorcycles": 664,
  "bicycles": 97
}, {
  "year": "2003",
  "cars": 1246,
  "motorcycles": 648,
  "bicycles": 93
}, {
  "year": "2004",
  "cars": 1318,
  "motorcycles": 697,
  "bicycles": 111
}, {
  "year": "2005",
  "cars": 1213,
  "motorcycles": 633,
  "bicycles": 87
}, {
  "year": "2006",
  "cars": 1199,
  "motorcycles": 621,
  "bicycles": 79
}, {
  "year": "2007",
  "cars": 1110,
  "motorcycles": 210,
  "bicycles": 81
}, {
  "year": "2008",
  "cars": 1165,
  "motorcycles": 232,
  "bicycles": 75
}, {
  "year": "2009",
  "cars": 1145,
  "motorcycles": 219,
  "bicycles": 88
}, {
  "year": "2010",
  "cars": 1163,
  "motorcycles": 201,
  "bicycles": 82
}, {
  "year": "2011",
  "cars": 1180,
  "motorcycles": 285,
  "bicycles": 87
}, {
  "year": "2012",
  "cars": 1159,
  "motorcycles": 277,
  "bicycles": 71
}];

chart.dateFormatter.inputDateFormat = "yyyy";
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
dateAxis.renderer.minGridDistance = 60;
dateAxis.startLocation = 0.5;
dateAxis.endLocation = 0.5;
dateAxis.baseInterval = {
  timeUnit: "year",
  count: 1
}

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.tooltip.disabled = true;

let series = chart.series.push(new am4charts.LineSeries());
series.dataFields.dateX = "year";
series.name = "cars";
series.dataFields.valueY = "cars";
series.tooltip.background.fill = am4core.color("#FFF");
series.tooltip.getStrokeFromObject = true;
series.tooltip.background.strokeWidth = 3;
series.tooltip.getFillFromObject = false;
series.fillOpacity = 0.6;
series.strokeWidth = 2;
series.stacked = true;

let series2 = chart.series.push(new am4charts.LineSeries());
series2.name = "motorcycles";
series2.dataFields.dateX = "year";
series2.dataFields.valueY = "motorcycles";
series2.tooltip.background.fill = am4core.color("#FFF");
series2.tooltip.getFillFromObject = false;
series2.tooltip.getStrokeFromObject = true;
series2.tooltip.background.strokeWidth = 3;
series2.sequencedInterpolation = true;
series2.fillOpacity = 0.6;
series2.stacked = true;
series2.strokeWidth = 2;

let series3 = chart.series.push(new am4charts.LineSeries());
series3.name = "bicycles";
series3.dataFields.dateX = "year";
series3.dataFields.valueY = "bicycles";
series3.tooltip.background.fill = am4core.color("#FFF");
series3.tooltip.getFillFromObject = false;
series3.tooltip.getStrokeFromObject = true;
series3.tooltip.background.strokeWidth = 3;
series3.sequencedInterpolation = true;
series3.fillOpacity = 0.6;
series3.defaultState.transitionDuration = 1000;
series3.stacked = true;
series3.strokeWidth = 2;

chart.cursor = new am4charts.XYCursor();
chart.cursor.xAxis = dateAxis;
chart.scrollbarX = new am4core.Scrollbar();

// Add a legend
chart.legend = new am4charts.Legend();
chart.legend.position = "top";


  }

}