<div class="auth-page-wrapper pt-5">


    <!-- auth page content -->
    <div class="auth-page-content">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center mt-sm-5 mb-4 text-white-50">
                        <div>
                            <p></p><a href="index.html" class="d-inline-block auth-logo">
                                <img src="assets/logops.png" alt="" height="250">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->

            <div class="row justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5">
                    <div class="card mt-4">

                        <div class="card-body p-4">
                            <div class="text-center mt-2">
                                <h5 class="text-primary">Bienvenido al Visor Censista Seguro</h5>
                                <p class="text-muted">Inicia sesión para continuar.</p>
                            </div>
                            <div class="p-2 mt-4">
                                <form  #myForm="ngForm" ngNativeValidate (ngSubmit)="ingresar()" autocomplete="off">

                                    <div class="mb-3">
                                        <label for="username" class="form-label">Usuario</label>
                                        <input ngModel name="login" type="text" class="form-control" id="login" placeholder="Ingrese usuario" [(ngModel)]="usuario" required>
                                    </div>

                                    <div class="mb-3">
                                        <label class="form-label" for="password-input">Contraseña</label>

                                        <div class="position-relative auth-pass-inputgroup mb-3">
                                            <input ngModel name="password" type="password" class="form-control pe-5 password-input" placeholder="Ingrese su password" id="password-input" [(ngModel)]="password" required>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <button class="btn btn-primary w-100" type="submit">Iniciar sesión</button>
                                    </div>

                                    
                                    <!-- Buttons with Label -->
                                    
                                    <br><br>
                                </form>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- end auth-page-wrapper -->
<p-toast></p-toast>

