import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { VisorComponent } from './page/visor/visor.component';

const routes: Routes = [
  
  { path: 'login', component: LoginComponent },
  { path: 'visor', component: VisorComponent, canActivate: [AuthGuard] },
  { path: '**', component: LoginComponent },
];



export const APP_ROUTES = RouterModule.forRoot(routes, {useHash: true});
