import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private usuarioService: ServiceService,
               private router: Router) {}

  canActivate() {

    if (this.usuarioService.isLoggedIn()) {
      return true;  
    } else {
      this.router.navigate(['/login']); 
      return false;
    }
  }
  
}
