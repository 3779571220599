import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  })

  isLoggedIn(): boolean {    
    const token = localStorage.getItem('token');
    return token !== null;
  }


  login(resp: any) {
    return fetch(`https://wscs.ine.gob.bo/login/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resp)
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  grafico() {
    return fetch(`https://wscs.ine.gob.bo/asignacion/grafico`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  graficoHora() {
    return fetch(`https://wscs.ine.gob.bo/asignacion/graficoHora`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  departamento() {
    return fetch(`https://wscs.ine.gob.bo/asignacion/departamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  listaDepartamento() {
    return fetch(`https://wscs.ine.gob.bo/asignacion/listaDepartamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  listar(depto: string) {
    return fetch(`https://wscs.ine.gob.bo/asignacion/listar`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  referencias(data: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${data}&key=AIzaSyCmoXXMdjujnh1XvUvz-rnU63NYC_d669I`;
    return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(`Error de red: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      console.log(data)
      return data['results'];
    })
    .catch(error => {
      console.error('Error en la solicitud:', error);
    });
  }


  /*departamento() {
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/departamento`);
  }

  listaDepartamento() {
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/listaDepartamento`);
  }

  listar(id: number, depto: string){
    return this.http.get(`https://ehrest2.ine.gob.bo/creacion/listar/${id}/${depto}`);
  }*/
}
