import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit {
tipo: string='password';
usuario: string;
password: string;
  
constructor( @Inject(DOCUMENT) private _document:any, public router: Router, public _usuarioService: ServiceService, private messageService: MessageService){}

  ngOnInit(){}

  passwordInput(){
    this.tipo=== 'password' ? this.tipo='text' : this.tipo='password';
    this._document.getElementById('password-input').setAttribute('type',this.tipo);
  }

  async ingresar(){
    const valor = {
      login: this.usuario,
      password: this.password
    }
    const resul = await this._usuarioService.login(valor);
    if(resul.ok){
      localStorage.setItem('login', resul.login);
      localStorage.setItem('token', resul.token);
      this.router.navigateByUrl('/visor');
    } else {
      this.messageService.add({severity:'error', summary: 'Error', detail: resul.msg});
    }
  }
}
